import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import './ScrollableUpload.css';
import MultiUploadCard from './MultiUploadCard';

const UploadScrollableCard = (props) => {
  const { documents = [], handleDocChange, deletDoc, isEdit = true } = props;

  const poDocs = documents?.filter((e) => e.type === 'WO_PURCHASE_ORDER') ?? [];
  const otherDocs = documents?.filter((e) => e.type === 'WO_OTHERS') ?? [];
  const signedDocs = documents?.filter((e) => e.type === 'WO_BRAND_AGREEMENT') ?? [];

  const DOCUMENTS_UPLOAD = [
    {
      id: 1,
      label: 'Purchase Order',
      fileName: 'Purchase_order',
      documents: poDocs,
      type: 'WO_PURCHASE_ORDER',
      entity: 'WORK_ORDER',
      required: true,
    },
    {
      id: 2,
      label: 'Signed Brand Agreement',
      fileName: 'Signed_brand_agreement',
      documents: signedDocs,
      type: 'WO_BRAND_AGREEMENT',
      entity: 'WORK_ORDER',
    },
    {
      id: 2,
      fileName: 'Work_order_Others',
      label: 'Others',
      documents: otherDocs,
      type: 'WO_OTHERS',
      entity: 'WORK_ORDER',
    },
  ];

  return (
    <Card style={{ width: '100%', padding: '3px', background: 'var(--Gray-50, #F9FAFB)' }}>
      <Card.Header
        style={{
          padding: 10,
          border: 'none',
          maxHeight: '45px',
          background: 'var(--Gray-50, #F9FAFB)',
        }}>
        <div>
          <p className="cardHead">Upload Documents</p>
          <span>Jpeg, Png, Pdf., Max. file size 10 mb</span>
        </div>
      </Card.Header>
      <Card.Body style={{ maxHeight: '200px', overflowY: 'scroll' }}>
        {DOCUMENTS_UPLOAD?.map((item, index) => (
          <div style={{ marginBottom: 5 }} key={index}>
            <MultiUploadCard
              FILE_NAME={item?.fileName}
              isEdit={isEdit}
              handleDocChange={handleDocChange}
              deletDoc={deletDoc}
              canUpdate={true}
              keyInd={index + 111}
              label={item?.label}
              required={item?.required}
              documents={item?.documents || []}
              type={item?.type}
            />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default UploadScrollableCard;
