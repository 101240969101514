import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { isEmpty } from 'lodash';
import { AUTHORITY } from '../../Constants/Constants';

/**
 * this component is authorise route and component as per role, permission and auth of user
 * @param props
 * @return {props.children || failure component}
 */

const usePermissionData = () => {
  const { userAuthorities, userRoles, userPermissions } = useSelector(({ account }) => {
    const {
      authorities: userAuthorities,
      eprRoles: userRoles,
      eprPermissions: userPermissions,
    } = account?.user;
    return { userAuthorities, userRoles, userPermissions };
  });

  return { userAuthorities, userRoles, userPermissions };
};
const HasAuthority = (props) => {
  const { hasPermission, hasRoles, hasAuth } = useAuthority();
  const { authorities, roles, permissions, noAuthorities, failureComponent } = props;

  return (
    <>
      {hasPermission(permissions) && hasAuth(authorities, noAuthorities) && hasRoles(roles)
        ? props.children
        : failureComponent || null}
    </>
  );
};

HasAuthority.propTypes = {
  authorities: PropTypes.array,
  noAuthorities: PropTypes.array,
  permissions: PropTypes.array,
  roles: PropTypes.array,
  failureComponent: PropTypes.any,
};

export default HasAuthority;

/**
 * Hook useful to check whether user has given permission, auth or roles
 * @return {{hasAuth: (function(*=, *=): boolean|boolean), hasPermission: (function(*=): boolean), hasPro: (function(): boolean), hasRoles: (function(*=): boolean)}}
 */
export const useAuthority = () => {
  const { userAuthorities, userRoles, userPermissions } = usePermissionData();
  const hasPermission = (permissions) => {
    return isEmpty(permissions) || intersection(userPermissions, permissions)?.length > 0;
  };

  const hasRoles = (roles) => {
    return isEmpty(roles) || intersection(userRoles, roles)?.length > 0;
  };

  const hasAuth = (auth, noAuthorities) => {
    return (
      (isEmpty(auth) || intersection(userAuthorities, auth)?.length > 0) &&
      (isEmpty(noAuthorities) || intersection(userAuthorities, noAuthorities)?.length <= 0)
    );
  };

  const hasAuth2 = (auth, noAuthorities) => {
    const isPrefix = (authority, role) => {
      if (typeof authority !== `string` || typeof role !== `string`) {
        throw new TypeError('Data Type Error while authenticating');
      }
      if (authority.length > role.length) return false;
      return role.startsWith(authority);
    };
    return (
      (isEmpty(auth) || userAuthorities.some((ua) => auth.some((aut) => isPrefix(aut, ua)))) &&
      (isEmpty(noAuthorities) ||
        !userAuthorities.some((ua) => noAuthorities.some((noAut) => isPrefix(noAut, ua))))
    );
  };

  const customerType = () => {
    const fin = Object.entries(AUTHORITY)
      .filter(([ct, auth]) => hasAuth([auth]))
      .map((item) => item?.at(0));
    return fin;
  };

  const hasPro = () => {
    return userAuthorities.indexOf(AUTHORITY.PRO) >= 0;
  };

  return { hasPermission, hasRoles, hasAuth, hasPro, customerType, hasAuth2 };
};
