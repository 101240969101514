import { faRupeeSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import find from 'lodash/find';
import React from 'react';
import { Button, Table } from 'react-bootstrap';
import { useSortBy, useTable } from 'react-table';
import copyicon from '../../../assets/img/copy.svg';
import deleteicon from '../../../assets/img/delete.svg';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import {
  AUTHORITY2,
  PROCESSING_TYPE_OPTIONS,
  UNIT_TYPES,
} from '../../../shared/Constants/Constants';
import { roundOffAmount } from '../../../shared/Utils/Utils';
import { ColumnSort, DropDownCell, InputCell } from '../TableCells/TableCells';
import './WorkOrderItemsEdit.scss';
import { useAccount } from '../../../hooks';

function BrandWorkOrderItemsEdit(props) {
  const {
    basicDetails,
    updateHandler,
    addNewRowHandler,
    deleteRowHandler,
    copyRowHandler,
    deliveryExists,
    rowsToHighlight,
    browseNodes,
    regions,
    addRow,
    isHUL,
    isUserPRO,
    handleKeyDown,
    isNotMandatory,
  } = props;

  const { hasAuth } = useAuthority();
  const isBrand = hasAuth([AUTHORITY2.BRAND]);
  const isPRO = hasAuth([AUTHORITY2.PRO]);
  const account = useAccount();

  const getMaterialCategories = () => {
    return browseNodes?.map((item) => {
      return { label: item.title, value: item.id };
    });
  };

  const getMaterials = (cellInfo) => {
    return find(browseNodes, {
      id: cellInfo?.row?.values?.browseNodeId,
    })?.items?.map((item) => {
      return { label: item.name, value: item.id };
    });
  };

  const getStates = (cellInfo) => {
    return cellInfo?.row?.values?.itemId
      ? regions.map((item) => {
          return { label: item.name, value: item.id ?? null };
        })
      : [];
  };

  const getDistricts = (cellInfo) => {
    return find(regions, {
      id: Number(cellInfo?.row?.values?.stateId),
    })?.districts?.map((item) => {
      return { label: item.name, value: item.id ?? null };
    });
  };

  const getTargetMaterials = (materialCategory) => {
    if (materialCategory?.row?.values?.browseNodeId) {
      return browseNodes
        ?.find((data) => data?.id === materialCategory?.row?.values?.browseNodeId)
        ?.items?.map((data) => {
          return {
            label: data?.name,
            value: data?.id,
          };
        });
    }
  };

  const getTargetStates = () => {
    return regions?.map((data) => {
      return {
        label: data?.name,
        value: data?.id,
      };
    });
  };

  const getTargetDistricts = (stateValue) => {
    if (stateValue) {
      return regions
        ?.find((data) => data?.id === stateValue)
        ?.districts?.map((data) => {
          return {
            label: data?.name,
            value: data?.id,
          };
        });
    }
  };

  const checkFullFilledQtyExists = (fullfilledQty) =>
    isUserPRO ? deliveryExists : fullfilledQty > 0;

  const checkIfPlannedQtyExists = (plannedQty) => (!isBrand ? deliveryExists : plannedQty > 0);

  const isAddRow = () => (isUserPRO ? !deliveryExists : true);

  const isAllocateTargetLessThanFullfilledQty = (allocateTarget, fullfilledQty) =>
    allocateTarget < fullfilledQty;

  let columns = React.useMemo(
    () => [
      {
        Header: 'Material category',
        accessor: 'browseNodeId',
        hidden: true,
        Cell: (cellInfo) => {
          const categoriesForTheCell = getMaterialCategories();
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <DropDownCell
                  cellInfo={cellInfo}
                  controlName="browseNodeId"
                  // disabled={cellInfo?.cell?.row?.original?.id}
                  updateHandler={updateHandler}
                  required={!isNotMandatory}
                  list={categoriesForTheCell}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Material Type',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <DropDownCell
                  cellInfo={cellInfo}
                  updateHandler={updateHandler}
                  controlName="itemId"
                  disabled={!getMaterials(cellInfo)?.length}
                  required={!isNotMandatory}
                  list={getMaterials(cellInfo)}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
              {cellInfo?.cell?.value === undefined && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    textAlign: 'center',
                    marginBottom: '0px',
                  }}>
                  Mismatch
                </p>
              )}
            </>
          );
        },
      },

      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <DropDownCell
                  cellInfo={cellInfo}
                  controlName="stateId"
                  required={false}
                  updateHandler={updateHandler}
                  list={getStates(cellInfo)}
                />
              </div>
            </>
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          return (
            <>
              <DropDownCell
                cellInfo={cellInfo}
                controlName="districtId"
                disabled={!getDistricts(cellInfo)?.length}
                required={false}
                updateHandler={updateHandler}
                list={getDistricts(cellInfo)}
              />
              {cellInfo?.cell?.value === undefined && (
                <p
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    textAlign: 'center',
                    marginBottom: '0px',
                  }}>
                  Mismatch
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'Credit Type',
        accessor: 'creditType',
        Cell: (cellInfo) => {
          const disabled = () => {
            return ['3', '5', '7']?.includes(cellInfo?.cell?.row?.original?.browseNodeId);
          };
          return (
            <div className={'d-inline-flex  align-items-center'}>
              <DropDownCell
                cellInfo={cellInfo}
                updateHandler={updateHandler}
                list={PROCESSING_TYPE_OPTIONS}
                disabled={disabled()}
                required={!disabled()}
              />
              {!disabled() && !cellInfo?.cell?.value && (
                <p
                  style={{
                    color: 'red',
                    marginLeft: '2px',
                    marginBottom: '0px',
                  }}>
                  *
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Quantity in Kgs',
        accessor: 'qty',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <InputCell
                  cellInfo={cellInfo}
                  controlName="allocatedTarget"
                  required={!isNotMandatory}
                  min={deliveryExists ? cellInfo?.row?.original?.fulQty : 0}
                  inputType={'number'}
                  step={'any'}
                  placeholder={'Enter target'}
                  updateHandler={updateHandler}
                  handleKeyDown={handleKeyDown}
                />
                &nbsp;{UNIT_TYPES['KG']?.displayText}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
              {!isUserPRO && (
                <p className="allocateTargetErrorText">
                  {isAllocateTargetLessThanFullfilledQty(
                    cellInfo?.cell?.row?.original?.qty,
                    cellInfo?.cell?.row?.original?.fulfilledQty
                  ) &&
                    `Allocate Taregt is less than fullfilled qty(${cellInfo?.cell?.row?.original?.fulfilledQty})`}
                </p>
              )}
            </>
          );
        },
      },

      {
        Header: 'Price per unit',
        accessor: 'pricePerUnit',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <FontAwesomeIcon icon={faRupeeSign} />
                &nbsp;&nbsp;
                <InputCell
                  cellInfo={cellInfo}
                  required={!isNotMandatory}
                  min={0}
                  step={'any'}
                  controlName="pricePerUnit"
                  inputType={'number'}
                  placeholder={'Enter price'}
                  disabled={
                    cellInfo?.row?.original?.plannedQty > 0 ||
                    checkFullFilledQtyExists(cellInfo?.cell?.row?.original?.fulfilledQty)
                  }
                  updateHandler={updateHandler}
                  handleKeyDown={handleKeyDown}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Amount',
        accessor: 'lineTotal',
        Cell: (cellInfo) => {
          return (
            <div className={'d-inline-flex  align-items-center mt-3'}>
              <FontAwesomeIcon icon={faRupeeSign} /> &nbsp;
              {roundOffAmount(cellInfo?.cell?.value)}
            </div>
          );
        },
      },
      {
        Header: 'PO Line Item',
        accessor: 'poLineItem',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <InputCell
                  cellInfo={cellInfo}
                  required={!isNotMandatory}
                  step={'any'}
                  controlName="pricePerUnit"
                  inputType={'number'}
                  placeholder={'Enter po line Item'}
                  disabled={checkFullFilledQtyExists(cellInfo?.cell?.row?.original?.fulfilledQty)}
                  updateHandler={updateHandler}
                  handleKeyDown={handleKeyDown}
                />
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    *
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Actions',
        accessor: 'none',
        Cell: (cellInfo) => {
          return (
            <div
              className="mt-2"
              style={
                isUserPRO && deliveryExists ? { pointerEvents: 'none' } : { pointerEvents: 'auto' }
              }>
              <img src={copyicon} alt="copy" onClick={() => copyRowHandler(cellInfo?.row?.index)} />
              {!checkFullFilledQtyExists(cellInfo?.cell?.row?.original?.fulfilledQty) &&
                !checkIfPlannedQtyExists(cellInfo?.cell?.row?.original?.plannedQty) && (
                  <img
                    className="ml-3"
                    src={deleteicon}
                    alt="delete"
                    onClick={() => deleteRowHandler(cellInfo?.row?.index)}
                  />
                )}
            </div>
          );
        },
      },
    ],
    [basicDetails]
  );

  let targetColumns = React.useMemo(
    () => [
      {
        Header: 'Material category',
        accessor: 'browseNodeId',
        hidden: true,
        Cell: (cellInfo) => {
          const browseNodeValue = browseNodes?.find(
            (data) => data?.id === cellInfo?.cell?.value
          )?.title;
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {browseNodeValue
                    ? browseNodeValue
                    : cellInfo?.cell?.value && (
                        <span style={{ color: 'red' }}>Material category not present</span>
                      )}
                </p>
                {cellInfo?.cell?.value === undefined && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
                {cellInfo?.cell?.value === null && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Material category is mandatory
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'Material Type',
        accessor: 'itemId',
        Cell: (cellInfo) => {
          const materialItems = getTargetMaterials(cellInfo);
          const browseNodeValue =
            cellInfo?.cell?.row?.values?.browseNodeId &&
            browseNodes
              ?.find((data) => data?.id === cellInfo?.cell?.row?.values?.browseNodeId)
              ?.items?.find((item) => item?.id === cellInfo?.cell?.row?.values?.itemId)?.name;
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {browseNodeValue
                    ? materialItems?.find((data) => data?.value === cellInfo?.cell?.value)?.label
                    : cellInfo?.cell?.value && (
                        <span style={{ color: 'red' }}>Material item not present</span>
                      )}
                </p>
                {cellInfo?.cell?.value === undefined && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
                {cellInfo?.cell?.value === null && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Material item is mandatory
                  </p>
                )}
              </div>
            </>
          );
        },
      },
      {
        Header: 'State',
        accessor: 'stateId',
        Cell: (cellInfo) => {
          const itemStates = getTargetStates(cellInfo);
          return (
            <>
              <p
                style={{
                  marginBottom: '0px',
                }}>
                {itemStates?.find((data) => data?.value === cellInfo?.cell?.value)?.label}
              </p>
              {cellInfo?.cell?.value === undefined &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
              {cellInfo?.cell?.value === null && (
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  NA
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'District',
        accessor: 'districtId',
        Cell: (cellInfo) => {
          const itemDistricts = getTargetDistricts(cellInfo?.cell?.row?.values?.stateId);
          const rowDetails = basicDetails?.workOrderItems[cellInfo?.cell?.row?.index];
          return (
            <>
              <p
                style={{
                  marginBottom: '0px',
                }}>
                {itemDistricts?.find((data) => data?.value === cellInfo?.cell?.value)?.label}
              </p>
              {rowDetails?.isSameDetailsExist &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    District level target is mandatory for this combination
                  </p>
                )}
              {cellInfo?.cell?.value === undefined &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '3' &&
                cellInfo?.cell?.row?.values?.browseNodeId !== '5' && (
                  <p
                    style={{
                      color: 'red',
                      marginBottom: '0px',
                    }}>
                    Mismatch
                  </p>
                )}
              {cellInfo?.cell?.value === null && !rowDetails?.isSameDetailsExist && (
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  NA
                </p>
              )}
            </>
          );
        },
      },
      {
        Header: 'Quantity in Kgs',
        accessor: 'qty',
        Cell: (cellInfo) => {
          return (
            <>
              <div className={'d-inline-flex  align-items-center'}>
                <p
                  style={{
                    marginBottom: '0px',
                  }}>
                  {cellInfo?.cell?.value}
                </p>
                {cellInfo?.cell?.value && <>&nbsp;{UNIT_TYPES['KG']?.displayText}</>}
                {!cellInfo?.cell?.value && (
                  <p
                    style={{
                      color: 'red',
                      marginLeft: '2px',
                      marginBottom: '0px',
                    }}>
                    Allocate the Quantity
                  </p>
                )}
              </div>
            </>
          );
        },
      },
    ],
    [basicDetails]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: addRow ? columns : targetColumns,
      data: basicDetails.workOrderItems,
      initialState: {
        hiddenColumns: [!isHUL && 'poLineItem', !isPRO && 'sac'],
      },
    },
    useSortBy
  );

  return (
    <Table
      {...getTableProps()}
      responsive
      className="table-bordered border-bottom-0 work-order-items-edit">
      <thead className="thead-light table-heading">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <div className={'d-flex flex-row align-items-center'}>
                  {column.render('Header')}
                  <span className={'ml-auto mr-1'}>
                    {column.canSort ? <ColumnSort column={column} /> : null}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows?.length ? (
          <>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={classNames('', {
                    'highlight-row': rowsToHighlight?.includes(index),
                  })}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </>
        ) : (
          <tr>
            <td colSpan={columns?.length}>
              <p className={'text-center m-3 text-danger'}>
                At least one target allocation is required to save.
              </p>
            </td>
          </tr>
        )}
        {addRow && (
          <tr>
            {isAddRow() && (
              <td className={'border-0 pl-0'}>
                <Button
                  variant={'light'}
                  className={'custom-btn-small-light'}
                  onClick={addNewRowHandler}>
                  + Add Row
                </Button>
              </td>
            )}
            <td colSpan={5} className={'text-right text-medium font-semibold border-0'}>
              Total
            </td>
            <td className={'border-bottom font-semibold'}>
              <FontAwesomeIcon icon={faRupeeSign} />
              &nbsp;
              {rows
                .map((row) => row?.values?.lineTotal)
                ?.reduce((prev, next) => roundOffAmount(prev + next), 0)}
            </td>
          </tr>
        )}
        <p style={{ marginTop: '5px' }}>Count: {basicDetails?.workOrderItems?.length}</p>
      </tbody>
    </Table>
  );
}

export default BrandWorkOrderItemsEdit;
