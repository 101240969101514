import React, { useEffect, useState } from 'react';
import UploadIconM from '../../../assets/imageComponents/UploadIconM';
import './ScrollableUpload.css';
import { Card } from 'react-bootstrap';
import { Link } from '@material-ui/core';
import { toast } from 'react-toastify';
import { uploadDocument } from '../../Utils/FileUpload';
import DeleteICon from '../../../assets/imageComponents/DeleteICon';
import ArrowUp from '../../../assets/imageComponents/ArrowUp';

function MultiUploadCard(props) {
  const {
    label,
    required,
    documents = [],
    deletDoc,
    type,
    keyInd,
    isEdit,
    FILE_NAME,
    handleDocChange,
  } = props;

  const [docums, setDocums] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [inproDoc, setInProDoc] = useState([]);

  useEffect(() => {
    setDocums(documents);
  }, [documents]);

  useEffect(() => {
    if (inproDoc?.length) {
      const docs = inproDoc.filter((e) => e.document.status === 'DONE');
      handleDocChange(docs);
      setInProDoc([]);
    }
  }, [inproDoc?.length]);

  const uploadDocumentToS3 = (file, delDocId, type, count, index) => {
    const fileDetails = {
      active: true,
      entity: 'WORK_ORDER',
      fileName: file.name,
      fileSize: file.size,
      contentType: file.type,
      type: type,
    };
    let docTobeUploaded = {};

    const signedURLCallback = (doc) => {
      docTobeUploaded = { id: delDocId, document: doc };
      setDocums((oldDocs) => [...(oldDocs || []), docTobeUploaded]);
    };

    const successCallback = (signedURLResponse) => {
      docTobeUploaded.document.status = 'DONE';
      setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
    };

    const failureCallback = (error) => {
      setUploadStatus({
        ...uploadStatus,
        [docTobeUploaded?.document?.fileName]: {
          status: 'FAILED',
          uploading: false,
          progress: 0,
        },
      });
      docTobeUploaded.document.status = 'FAIL';
      // setInProDoc((oldObj) => [...(oldObj || []), docTobeUploaded]);
      toast.error('Failed to upload the document');
    };

    uploadDocument(fileDetails, file, signedURLCallback, successCallback, failureCallback);
  };

  const handleFileSelected = (files, docId, type, id) => {
    // eslint-disable-next-line no-unused-expressions
    Object.keys(files)?.forEach((file, index) =>
      uploadDocumentToS3(files[file], docId, type, files.length, index)
    );
    document.getElementById(id).value = '';
  };

  return (
    <Card className="multiUploadCard" style={{ background: !isEdit && 'var(--Gray-50, #F9FAFB)' }}>
      <Card.Header
        style={{
          padding: 3,
          margin: 0,
          border: !docums?.length && 'none',
          background: !isEdit && 'var(--Gray-50, #F9FAFB)',
        }}
        className="d-flex just-space-betw">
        <span className="uploadCardHead">
          {label} {required && <span style={{ color: 'red' }}>*</span>}{' '}
        </span>

        {isEdit ? (
          <div style={{ cursor: 'pointer' }}>
            <label htmlFor={`file-upload-${keyInd}`} style={{ marginRight: '5px' }}>
              <UploadIconM />
            </label>

            <input
              accept="application/pdf,image/png,image/jpeg"
              type="file"
              id={`file-upload-${keyInd}`}
              style={{ display: 'none' }}
              hidden
              multiple
              onChange={(e) =>
                handleFileSelected(e.target.files, null, type, `file-upload-${keyInd}`)
              }
            />
          </div>
        ) : (
          <ArrowUp />
        )}
      </Card.Header>
      {docums?.length ? (
        <Card.Body style={{ minHeight: '10px' }}>
          {docums?.map((doc_i, ind) => (
            <div key={ind + 444} className="d-flex align-i-center">
              {isEdit && (
                <div style={{ marginRight: '6px' }} onClick={() => deletDoc(doc_i)}>
                  <DeleteICon />
                </div>
              )}
              <div>
                <Link key={ind + 222} target="_blank" href={`${doc_i?.viewUrl}`} underline="always">
                  {`${FILE_NAME}_${ind + 1}`}
                </Link>
              </div>
            </div>
          ))}
        </Card.Body>
      ) : null}
    </Card>
  );
}

export default MultiUploadCard;
