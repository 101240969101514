import React from 'react';
import { Col, Form } from 'react-bootstrap';

const POInputForm = ({ workOrderForm, index, handleWorkOrderChange, ...props }) => {
  return (
    <div className="existing-po-input">
      <Form.Group>
        <Form.Label className="mb-0" style={{ display: 'block' }}>
          Existing PO? <span className="mandatory-astrik-color">*</span>
        </Form.Label>
        {[
          { id: '1', label: 'Yes', value: true },
          { id: '2', label: 'No', value: false },
        ].map((item, id) => (
          <Form.Check
            key={`ExistingPO-${id}`}
            inline
            type="radio"
            name={`ExistingPO-${id}`}
            id={`inline-radio-${id + 1}`}
            label={item?.label}
            className="vendor-traceability-radio mt-3"
            onChange={() => handleWorkOrderChange(index, 'hasAdvancePo', item.value)}
            checked={
              workOrderForm?.values?.selectedClientData?.[index]?.hasAdvancePo === item.value
            }
          />
        ))}
      </Form.Group>
      {workOrderForm?.values?.selectedClientData?.[index]?.hasAdvancePo && (
        <Form.Group as={Col} className="mb-4 planning-form-row-large">
          <Form.Label className="mb-0 text-medium">
            PO <span style={{ color: 'red' }}>*</span>
          </Form.Label>
          <Form.Control
            {...workOrderForm.getFieldProps(`selectedClientData[${index}].hasAdvancePo`)}
            id="PONumber"
            className="p-0 float-label"
            type="text"
            min={1}
            placeholder="PO Number"
            value={workOrderForm?.values?.selectedClientData?.[index]?.poNumber}
            onChange={(event) => handleWorkOrderChange(index, 'poNumber', event?.target?.value)}
          />
          {workOrderForm?.touched?.selectedClientData?.[index]?.poNumber &&
            !!workOrderForm?.errors?.selectedClientData?.[index]?.poNumber && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                {workOrderForm?.errors?.selectedClientData?.[index]?.poNumber}
              </div>
            )}
        </Form.Group>
      )}
    </div>
  );
};

export default POInputForm;
