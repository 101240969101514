import React from 'react';
import { DropdownSelect, RcylDatePicker } from '../../shared/Components';
import { Form, InputGroup } from 'react-bootstrap';
import SearchIcon from '../../assets/img/search-icon-grey.svg';
import './WorkOrder.scss';
import { useSelector } from 'react-redux';

function renderColumn(param, filterValue, setFilter, clients) {
  switch (param) {
    case 'id':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'fromCustomerId':
      return (
        <DropdownSelect
          options={clients}
          getOptionLabel={(option) => `${option?.name}`}
          getOptionValue={(option) => `${option?.id}`}
          noOptionsMessage={() => 'Options not found!'}
          onChange={(e) => {
            setFilter(e?.id);
          }}
          value={filterValue || ''}
          className="column-filter-dropdown"
          placeholder="Select"
          isClearable={true}
        />
      );
    case 'poNumber':
      return (
        <InputGroup>
          <Form.Control
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search"
            className="column-filter-input"
          />
          <InputGroup.Text className="column-filter-input-icon">
            <img src={SearchIcon} width="15px" height="15px" alt="search" />
          </InputGroup.Text>
        </InputGroup>
      );
    case 'createdOn':
      return (
        // <RcylDatePicker
        //   value={filterValue || ''}
        //   inputClass="column-filter-input"
        //   max={new Date().toISOString().split('T')[0]}
        //   placeholder=""
        //   showDropdowns={true}
        //   singleDatePicker={true}
        //   startDate={filterValue}
        //   onApply={({ startDate, endDate }) => {
        //     console.log(
        //       'choco🚀 ~ renderColumn ~ startDate, endDate :',
        //       startDate,
        //       toLocalDate(endDate)
        //     );
        //     setFilter(toLocalDate(endDate));
        //   }}
        // />
        <Form.Control
          type="date"
          value={filterValue || ''}
          onChange={(e) => setFilter(e.target.value)}
          className="column-filter-input"
          max={new Date().toISOString().split('T')[0]}
          placeholder=""
        />
      );
    case 'poDate':
      return (
        // <RcylDatePicker
        //   value={filterValue || ''}
        //   inputClass="column-filter-input"
        //   max={new Date().toISOString().split('T')[0]}
        //   placeholder=""
        //   showDropdowns={true}
        //   singleDatePicker={true}
        //   startDate={filterValue}
        //   onApply={({ startDate }) => {
        //     setFilter(startDate);
        //   }}
        // />
        <Form.Control
          type="date"
          value={filterValue || ''}
          onChange={(e) => setFilter(e.target.value)}
          className="column-filter-input"
          max={new Date().toISOString().split('T')[0]}
          placeholder=""
        />
      );
    default:
      return;
  }
}

function ColumnFilters({ column }) {
  const { filterValue, setFilter } = column;
  const { clients } = useSelector(({ account }) => ({
    clients: account.clients,
  }));
  return <span>{renderColumn(column?.id, filterValue, setFilter, clients)}</span>;
}

export default ColumnFilters;
