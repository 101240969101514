import React from 'react';
import { detailedDiff } from 'deep-object-diff';
import { zip, omit, isEmpty, mapValues, get, mapKeys } from 'lodash';
import { useRegionsMap } from '../../../hooks';
import { toCapitalize, toLocalDate } from '../../../shared/Utils/Helper';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

function flattenObj(obj, parent, res = {}) {
  for (let key in obj) {
    let propName = parent ? parent + '.' + key : key;
    if (typeof obj[key] == 'object') {
      flattenObj(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
}

function WorkOrderChangeLog({ logs, userList, browseNodes, workOrder, showModal, handleClose }) {
  const { clients, serviceProviders } = useSelector(({ account, serviceProvider }) => ({
    clients: account.clients,
    serviceProviders: serviceProvider.listLight,
  }));

  let regionsMap = useRegionsMap();
  if (!logs?.length) return <></>;

  const diffs = zip([null, ...logs], logs)
    .filter(([prev, current]) => !!prev && !!current)
    .map(([prev, current]) => {
      let prevObj = prev && JSON.parse(prev.json);
      let currentObj = JSON.parse(current.json);

      [prevObj, currentObj] = [prevObj, currentObj].map((wo) => {
        return (
          wo && {
            'Service Provider':
              serviceProviders.find((e) => e.id === Number(wo?.toCustomerId))?.name || 'NA',
            'Start Date': toLocalDate(wo?.fromDate),
            'Estimated Delivery Date': toLocalDate(wo.toDate),
            Status: toCapitalize(wo.status),
            Item: wo.workOrderItems.map((woi) => {
              return {
                Material: woi?.itemName,
                State: regionsMap[woi?.stateId]?.name || 'NA',
                District: regionsMap[woi?.stateId]?.districts[woi?.districtId]?.name || 'NA',
                Target: woi?.qty,
                Rate: woi?.pricePerUnit,
                'Fulfilment Cycle': toCapitalize(woi?.fulfillmentCycle),
                'Delivery Date': toLocalDate(woi?.estimatedDelivery),
                'fulfilment plan':
                  !isEmpty(woi?.fulfilmentPlan) &&
                  mapKeys(woi?.fulfilmentPlan, function (value, key) {
                    return key ? toLocalDate(key) : '';
                  }),
              };
            }),
            'PO Number': wo?.poNumber,
            'Entity Type': wo?.entityType,
            'Customer Type': wo?.customerType,
            'Payment Term': wo?.paymentTerm,
            'Traceability Documents': wo?.traceabilityDocuments === true ? 'Yes' : 'No',
            Comments: wo?.extraComments,
          }
        );
      });

      let diff = prevObj && detailedDiff(currentObj, prevObj);
      if (diff) {
        diff.updated = mapValues(flattenObj(diff.updated), (v, k) => {
          return `${get(currentObj, k)} => ${v}`;
        });
        diff.deleted = Object.keys(flattenObj(diff.deleted));
      }
      return {
        ...omit(prev, 'json'),
        diff,
      };
    });

  const displayDifference = (diffObj) => {
    const changes = {
      previousValue: [],
      currentValue: [],
    };

    if (diffObj?.updated && Object.getOwnPropertyNames(diffObj?.updated).length > 0) {
      Object.keys(diffObj?.updated).forEach((key) => {
        const [previousValue, currentValue] = diffObj.updated[key].split(' => ');
        changes.previousValue.push({ key, value: previousValue });
        changes.currentValue.push({ key, value: currentValue });
      });
    }

    if (diffObj?.added && Object.getOwnPropertyNames(diffObj?.added).length > 0) {
      Object.keys(diffObj?.added).forEach((key) => {
        changes.previousValue.push({ key, value: 'NA' });
        changes.currentValue.push({ key, value: JSON.stringify(diffObj.added[key], null, 2) });
      });
    }

    if (diffObj?.deleted && diffObj?.deleted.length > 0) {
      diffObj.deleted.forEach((key) => {
        changes.previousValue.push({ key, value: JSON.stringify(diffObj.deleted[key], null, 2) });
        changes.currentValue.push({ key, value: 'NA' });
      });
    }

    return changes;
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      className="work-order-log-modal"
      size={'xl'}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Audit Trail</Modal.Title>
      </Modal.Header>

      <Modal.Body className="work-order-log">
        {!isEmpty(diffs) && (
          <div className="table-wrapper">
            <table border={1} cellPadding={10} className="w-100 work-order-log-table">
              <thead style={{ color: '#000000', backgroundColor: '#f5f5f5' }}>
                <tr>
                  <td>User & Timestamp</td>
                  <td>Action / Status update</td>
                  <td>Previous Value</td>
                  <td>Updated Value/ Comments</td>
                </tr>
              </thead>
              <tbody>
                {diffs.map((change) => {
                  const diffData = change?.diff
                    ? displayDifference(change.diff)
                    : { previousValue: [], currentValue: [] };

                  return (
                    <tr key={change?.changedBy}>
                      <td>
                        <p className="mb-3">
                          {userList?.some((user) => user?.id === change?.changedBy)
                            ? userList?.find((user) => user?.id === change?.changedBy)?.name
                            : serviceProviders?.some((user) => user?.id === change?.customerId)
                            ? serviceProviders?.find((user) => user?.id === change?.customerId)
                                ?.name
                            : clients?.find((user) => user?.id === change?.customerId)?.name ||
                              change?.changedByUserName}{' '}
                        </p>
                        {toLocalDate(change.changedOn, 'DD/MM/YYYY h:mm a') || 'NA'}
                      </td>

                      <td>{toCapitalize(change.status)}</td>
                      <td>
                        {diffData.previousValue.map((item, i) => (
                          <p key={i}>
                            {item.key}: <span className="prev-value">{item.value}</span>
                          </p>
                        ))}
                      </td>
                      <td>
                        {diffData.currentValue.map((item, i) => (
                          <p key={i}>
                            {item.key}: <span className="current-value">{item.value}</span>
                          </p>
                        ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default WorkOrderChangeLog;
