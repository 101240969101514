/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronLeft, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import * as Yup from 'yup';
import AccessDenied from '../../../features/Others/AccessDenied/AccessDenied';
import BrandWorkOrderItemsEdit from '../../../features/WorkOrder/WorkOrderItemsEdit/BrandWorkOrderItemsEdit';
import { WorkOrderService } from '../../../features/WorkOrder/workOrder.service';
import { useAccount } from '../../../hooks';
import { useServiceProviders } from '../../../hooks/useCustomers';
import { useBrowseNodeMap, useMaterialCategory } from '../../../hooks/useMaterialCategory';
import { useRegions } from '../../../hooks/useRegions';
import { DropdownSelect, RcylDatePicker } from '../../../shared/Components';
import { useAuthority } from '../../../shared/Components/HasAuthority/HasAuthorityNew';
import Loader from '../../../shared/Components/Loader/Loader';
import UploadScrollableCard from '../../../shared/Components/UploadScrollableCard/UploadScrollableCard';
import {
  AUTHORITY2,
  ENTITY_TYPE_OPTIONS,
  FULFILLMENT_CYCLES,
  TRACEABILITY_DOCS_OPTIONS,
} from '../../../shared/Constants/Constants';
import { toLocalDate } from '../../../shared/Utils/Helper';
import './WorkOrderCreate.scss';
import { validateAndReturnInvalidRowIndexes } from './WorkOrderCreateFormValidators';
import CommentsModal from '../../../features/WorkOrder/WorkOrderItemsEdit/CommentsModal';

const WorkOrderCreateBrandPRO = (props) => {
  const workOrderId = props.match.params.id;
  const account = useAccount();
  const { itemsById } = useBrowseNodeMap();
  let [isEdit] = useQueryParam('isEdit', StringParam);

  const isNonPlastic = account?.user?.businessVerticals?.some((vertical) =>
    ['TYRES', 'E_WASTE', 'BATTERIES'].includes(vertical)
  );

  const history = useHistory();
  const { hasAuth } = useAuthority();
  const isUserServiceProvider = hasAuth([AUTHORITY2.SERVICE_PROVIDER, AUTHORITY2.PRO]);
  const isUserPRO = hasAuth([AUTHORITY2.PRO]);
  const isBrand = hasAuth([AUTHORITY2.BRAND]);

  const NEW_ROW = {
    workOrderFor: null,
    browseNodeId: null,
    itemId: null,
    stateId: null,
    districtId: null,
    pricePerUnit: null,
    lineTotal: '',
    qty: '',
    estimatedDelivery: '',
    fulfillmentCycle: '',
    unallocatedTarget: '',
    itemUnit: '',
    poLineItem: account?.user?.profile?.id === 9053 ? '' : undefined,
    sac: null,
    taxRate: null,
  };
  const [renderCount, setRenderCount] = useState(0);
  const [rowsToHighlight, setRowsHighlight] = useState([]);
  const [updateSaveInProgress, setUpdateSaveInProgress] = useState(false);
  const [deliveryExists, setDeliveryExists] = useState(false);
  const [originalWorkOrderData, setOriginalWorkOrderData] = useState({});
  const [poDate, setPoDate] = useState('');
  const [commentValidation, setCommentValidation] = useState();
  const [orderSummary, setOrderSummary] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState();

  const serviceProviders = useServiceProviders(true);
  const { clients, financialYears } = useSelector(({ account }) => {
    return { clients: account.clients, financialYears: account?.financialYears };
  });

  const browseNodes = useMaterialCategory();
  const regions = useRegions();

  useEffect(() => {
    if (workOrderId) {
      doGetWorkOrderByID();
    }
    setRenderCount(renderCount + 1);
  }, []);

  const doGetWorkOrderByID = async () => {
    try {
      const response = await WorkOrderService.fetchWorkOrderById(workOrderId);
      if (response?.data) {
        if (isEdit) {
          response.data.workOrderItems.forEach(function (item) {
            item.fulQty = item.fulfilledQty;
            if (item.fulfilledQty !== null && item.fulfilledQty > 0) {
              setDeliveryExists(true);
            }
          });
        }
        setOriginalWorkOrderData({
          ...response?.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
          workOrderItems: response?.data?.workOrderItems?.map((wo_item) => ({
            ...wo_item,
            stateCode: wo_item?.stateCode !== null ? wo_item?.stateCode : 0,
            stateId: wo_item?.stateId !== null ? wo_item?.stateId : 0,
          })),
        });
      }
    } catch (e) {}
  };

  const workOrderForm = useFormik({
    enableReinitialize: isEdit,
    initialValues: {
      fromCustomerId: isBrand ? account?.user?.profile?.id : null,
      fromDate: '',
      toCustomerId: '',
      customerType: 'EPR',
      entityType: '',
      traceabilityDocuments: null,
      toBrandId: '',
      brandId: '',
      toDate: '',
      poNumber: '',
      poDate: '',
      paymentTerm: null,
      draft: false,
      isSaveButtonDisabled: false,
      workOrderItems: [NEW_ROW],
      documents: [],
      fulfilledYear: '',
      fulfillmentYearStartDate: '',
      fulfillmentYearEndDate: '',
      comments: '',
      isInvoicing: null,
      extraComments: '',
      ...originalWorkOrderData,
    },
    validationSchema: Yup.object({
      fromDate: Yup.string('Invalid date.').required('Start date required.'),
      toDate: Yup.string('Invalid date.')
        .typeError('Estimated Delivery date required')
        .required('Estimated delivery date required.'),
      poNumber: Yup.string('Invalid poNumber.').required('PO Number required.'),
      poDate: Yup.string('Invalid poDate.').required('PO Date required.'),
      paymentTerm: Yup.number('Invalid paymentTerm.').required('Payment Term required.'),
      entityType: Yup.string('Invalid entity type').required('Entity type required'),
      fulfillmentYearStartDate: Yup.string('Invalid date ').required('Fulfillment year required'),
      fulfillmentYearEndDate: Yup.string('Invalid entity type'),
      traceabilityDocuments: !isNonPlastic
        ? Yup.boolean().required('Traceability documents is required')
        : Yup.boolean().nullable(),
      id: Yup.string().nullable(true),
      draft: Yup.boolean().nullable(true),
      createdBy: Yup.number().nullable(true),
      totalAmount: Yup.number().nullable(true),
      fromCustomerId: Yup.number().required('Brand selection required'),
      workOrderType: Yup.string().nullable(true),
      status: Yup.string().nullable(true),
      createdOn: Yup.string().nullable(true),
      modifiedOn: Yup.string().nullable(true),
      workOrderItems: Yup.array(),
      documents: Yup.array().min(1, 'Atleast one doc is required'),
      extraComments: Yup.string(),
      ...commentValidation,
    }),
    isInitialValid: false,
    onSubmit: (values) => {
      prepareWorkOrderData({
        ...values,
      });
    },
  });

  useEffect(() => {
    if (renderCount) {
      workOrderForm.validateForm().then();
      setRowsHighlight(validateAndReturnInvalidRowIndexes(workOrderForm.values));
    }
  }, [workOrderForm?.values]);

  useEffect(() => {
    if (financialYears) {
      const yearIndex = financialYears?.length - 3;
      const yearValue = financialYears[yearIndex];
      setPoDate(yearValue?.value?.fromDate);
    }
  }, [financialYears]);

  useEffect(() => {
    WorkOrderService.getOrderSummary(
      account?.selectedFinancialYear?.fromDate,
      account?.selectedFinancialYear?.toDate
    ).then((os) => {
      setOrderSummary(os);
    });
  }, []);

  const prepareWorkOrderData = (requestData) => {
    requestData = {
      ...requestData,
      workOrderItems: [...requestData?.workOrderItems]?.map((row) => {
        return {
          ...row,
          fulfillmentCycle: workOrderForm?.values?.fulfillmentCycle,
          estimatedDelivery: workOrderForm?.values?.toDate,
          stateId: row?.stateId || null,
          taxRate: itemsById[row?.itemId]?.hsnCode?.tax,
        };
      }),
    };

    if (!isUserServiceProvider) {
      requestData = {
        ...requestData,
        customerType: workOrderForm?.values?.customerType,
        entityType: workOrderForm?.values?.entityType,
        traceabilityDocuments: workOrderForm?.values?.traceabilityDocuments,
      };
      requestData.workOrderItems.forEach((item, itemIndex) => {
        requestData.workOrderItems[itemIndex].workOrderFor = account?.user?.profile?.id;
      });
    }
    createOrUpdateWorkOrder(requestData, requestData.id ? false : true);
  };

  const getFulFillyearLabel = (dateVal) => {
    const fulFilledYear = account?.financialYears?.find(
      (item) => toLocalDate(item?.value?.fromDate) === toLocalDate(dateVal)
    )?.label;

    return fulFilledYear;
  };

  const createOrUpdateWorkOrder = async (requestData, createOperation) => {
    setUpdateSaveInProgress(true);
    requestData.documents = workOrderForm.values.documents;
    try {
      const response = createOperation
        ? await WorkOrderService.createWorkOrder({
            ...requestData,
            toCustomerId: 8651,
            salesOrderId: originalWorkOrderData?.salesOrderId,
            salesOrderNumber: originalWorkOrderData?.salesOrderNumber,
          })
        : await WorkOrderService.updateWorkOrder({ ...requestData, toCustomerId: 8651 });
      if (response?.status === 200 || response?.status === 201) {
        workOrderForm.resetForm({
          values: response.data,
          fulfilledYear: getFulFillyearLabel(response?.data?.fulfillmentYearStartDate),
          fulfillmentCycle: response?.data?.workOrderItems?.[0]?.fulfillmentCycle,
        });
        if (response?.data?.draft) {
          createOperation
            ? toast.success('Work order saved as draft successfully.')
            : toast.success('Work order draft updated successfully.');
          history.goBack();
        } else {
          toast.success(
            isProgress ? 'Work order updated successfully' : 'Work Order Submitted for review'
          );
        }
        if (!createOperation) {
          workOrderForm.setFieldValue('documents', [...(response?.data?.documents || [])]);
        }

        if (!response?.data?.draft) {
          history.push(`/work-orders-brandPRO/${response?.data?.id}?isCreateUpdate=true`);
        }
      }
    } catch (e) {
      setUpdateSaveInProgress(false);
    }
    setUpdateSaveInProgress(false);
  };

  const updateDataToTable = (rowIndex, columnId, value) => {
    const tableData = [...workOrderForm.values.workOrderItems];
    workOrderForm.setFieldValue(
      'workOrderItems',
      tableData.map((row, index) => {
        if (index === rowIndex) {
          switch (columnId) {
            case 'browseNodeId':
              const rowData = tableData[rowIndex];
              return {
                ...rowData,
                [columnId]: value,
                lineTotal:
                  Number(tableData[rowIndex]?.pricePerUnit) * Number(tableData[rowIndex]?.qty),
                itemId: null,
                stateId: null,
                districtId: null,
              };
            case 'itemId':
              const rowValues = tableData[rowIndex];
              return {
                ...tableData[rowIndex],
                [columnId]: value,
                stateId: isEmpty(value) ? null : rowValues.stateId,
                districtId: isEmpty(value) ? null : rowValues.districtId,
              };
            case 'sac':
              return {
                ...tableData[rowIndex],
                [columnId]: value,
              };
            case 'pricePerUnit':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(value),
                lineTotal: Number(value) * Number(tableData[rowIndex]?.qty),
              };
            case 'qty':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(value),
                lineTotal: Number(value) * Number(tableData[rowIndex]?.pricePerUnit),
              };
            case 'poLineItem':
              return {
                ...tableData[rowIndex],
                [columnId]: Number(value),
              };
            case 'stateId':
              return {
                ...tableData[rowIndex],
                [columnId]: value ? Number(value) : value,
                districtId: '',
              };
            case 'districtId':
              return {
                ...tableData[rowIndex],
                [columnId]: value ? Number(value) : value,
              };
            case 'creditType':
              return {
                ...tableData[rowIndex],
                [columnId]: value,
              };
            default:
          }
          return {
            ...tableData[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  useEffect(() => {
    if (uploadedDocs?.length) {
      workOrderForm.setValues({ ...workOrderForm.values, documents: uploadedDocs });
      setUploadedDocs([]);
    }
  }, [uploadedDocs?.length]);

  const documentAttach = async (documentReqData) => {
    const response = await WorkOrderService.uploadDocuments(documentReqData);
    if (response?.data) {
      const existingValue = workOrderForm.values.documents || [];
      existingValue.push(response?.data);
      setUploadedDocs(existingValue);
    }
  };
  const handleDocumentChange = (uploadDocList) => {
    uploadDocList.forEach((newData) => {
      newData.document.entityId = workOrderId;
      documentAttach(newData.document);
    });
  };

  const deletDocument = async (delDoc) => {
    try {
      if (delDoc.id) {
        await WorkOrderService.deleteWorkOrderDocument(delDoc.id);
      }
      let index = null;
      const doc = workOrderForm?.values?.documents;
      if (!!delDoc.id) {
        index = doc?.findIndex((e) => e.id === delDoc.id);
      } else {
        index = doc?.findIndex((e) => e.fileName === delDoc.fileName);
      }
      doc.splice(index, 1);
      workOrderForm.setFieldValue('documents', doc);
    } catch (e) {}
  };

  const deleteRow = (rowIndex) => {
    const workOrderItems = [...workOrderForm.values.workOrderItems];
    workOrderItems.splice(rowIndex, 1);
    workOrderForm.setFieldValue('workOrderItems', [...workOrderItems]);
  };

  const addOrCopyRow = (rowIndex) => {
    let newRow = { ...NEW_ROW };
    const { workOrderFor, browseNodeId, itemId, estimatedDelivery, fulfillmentCycle } =
      workOrderForm?.values?.workOrderItems[rowIndex] || [];
    if (workOrderForm.values.toDate) {
      newRow.estimatedDelivery = workOrderForm.values.toDate;
    }
    workOrderForm.setFieldValue('workOrderItems', [
      ...workOrderForm.values.workOrderItems,
      {
        ...NEW_ROW,
        ...{
          workOrderFor: workOrderFor,
          browseNodeId: browseNodeId,
          itemId: itemId || null,
          estimatedDelivery: estimatedDelivery,
          fulfillmentCycle: fulfillmentCycle ?? getFulfillmentCycle(),
        },
      },
    ]);
  };

  const getFulfillmentCycle = () => {
    let cycle = '';
    if (!!workOrderForm.values.toCustomerId) {
      cycle =
        serviceProviders.find((e) => e.id === Number(workOrderForm.values.toCustomerId))
          ?.fulfillmentcycle || '';
    }
    return cycle;
  };

  const dropDownHandler = (a) => {
    workOrderForm.handleChange(a);
  };

  const finYearHandler = (e) => {
    const DATES = e?.target?.value;

    const fulFilledYear = account?.financialYears?.find((item) => item?.label === DATES);

    workOrderForm.setValues({
      ...workOrderForm?.values,
      fulfillmentYearStartDate: fulFilledYear?.value?.fromDate,
      fulfillmentYearEndDate: fulFilledYear?.value?.toDate,
      fulfilledYear: fulFilledYear?.label,
    });
  };

  const isDataNotValid = rowsToHighlight.length || !workOrderForm?.dirty || updateSaveInProgress;

  const handleKeyDown = (event) => {
    const invalidCharecters = ['-', '+', 'e'];
    if (invalidCharecters.includes(event?.key)) {
      event.preventDefault();
    }
  };

  const hasPurchaseOrderDoc = workOrderForm?.values?.documents?.filter(
    (e) => e.type === 'WO_PURCHASE_ORDER'
  )?.length;

  const handleClientHandler = (e) => {
    workOrderForm.setFieldValue('fromCustomerId', e.id);
  };

  const isRejected = workOrderForm?.values?.status === 'REJECTED';
  const isDraft = workOrderForm?.values?.status === 'DRAFT';
  const isProgress = workOrderForm?.values?.status === 'IN_PROGRESS';

  const { hasPermission } = useAuthority();
  const canSave =
    isBrand ||
    (isUserPRO &&
      hasPermission({
        permissionsAllowed: [
          {
            groupName: 'Execute_Targets',
            moduleName: 'Work_Orders',
            name: isEdit ? 'can_edit' : 'can_create',
          },
        ],
      }));

  const handleClose = () => setModalShow(false);

  return (
    <div className={'animated fadeIn'}>
      <React.Suspense fallback={<Loader />}>
        <Card className="main-card ">
          <Form id="createWorkOrderForm" onSubmit={(e) => workOrderForm.handleSubmit(e)}>
            <Card.Header className="bg-white border-0  py-0">
              <Row className="padding-aligned align-self-center">
                <Col className="align-self-center" xs={'3'}>
                  <div className={'d-flex align-items-center'}>
                    <Button
                      className={'mr-2'}
                      onClick={() =>
                        isBrand
                          ? history.push('/work-orders')
                          : history.push('/work-orders-incoming')
                      }>
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    {workOrderForm?.values?.id ? (
                      <h4>Work order - {workOrderForm.values?.id}</h4>
                    ) : (
                      <h4 className="">Create Work Order</h4>
                    )}
                  </div>
                </Col>
                <Col className="pl-0 text-right justify-content-end">
                  <Button
                    className={'save-btn  text-dark font-regular mr-3 px-4'}
                    variant={'outline-secondary'}
                    onClick={() => history.goBack()}>
                    Cancel
                  </Button>

                  {((!isProgress && isEdit && !isRejected) ||
                    isDraft ||
                    (!isEdit &&
                      !['IN_PROGRESS', 'IN_REVIEW', 'CLOSED'].includes(
                        workOrderForm?.values?.status
                      ))) && (
                    <Button
                      className={'save-btn  text-dark font-regular mr-3 px-4'}
                      // type="submit"
                      disabled={isDataNotValid}
                      onClick={() => {
                        workOrderForm.setFieldValue('draft', true);
                        prepareWorkOrderData({ ...workOrderForm?.values, draft: true });
                      }}
                      variant={'outline-secondary'}>
                      Save as Draft
                    </Button>
                  )}

                  {canSave && (
                    <Button
                      className={'save-btn px-4'}
                      disabled={
                        !hasPurchaseOrderDoc ||
                        (!isDraft && !workOrderForm?.dirty) ||
                        !workOrderForm?.values?.workOrderItems?.length ||
                        rowsToHighlight.length ||
                        updateSaveInProgress ||
                        !isEmpty(workOrderForm?.errors) ||
                        workOrderForm?.values?.isSaveButtonDisabled
                      }
                      onClick={() => {
                        workOrderForm.setValues({
                          ...workOrderForm.values,
                          draft: false,
                          status: isProgress
                            ? 'IN_PROGRESS'
                            : !isNonPlastic
                            ? 'IN_REVIEW'
                            : 'IN_PROGRESS',
                        });
                      }}
                      type="submit">
                      {isProgress
                        ? 'Update Work Order'
                        : !isNonPlastic
                        ? 'Submit for Review'
                        : 'Create Work Order'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className={'p-0'}>
              {canSave ? (
                <Row>
                  <Col xs={12}>
                    <div className={'bg-secondary padding-2rem'}>
                      <Row>
                        <Col md={6}>
                          <h5 className="mb-1">Provide Work Order Details</h5>
                        </Col>
                        <Col md={6} className="text-right">
                          <Button
                            variant="secondary"
                            onClick={() => setModalShow(true)}
                            style={{ border: '1px solid #D0D5DD' }}>
                            {workOrderId && workOrderForm.values?.extraComments
                              ? 'Edit Comments'
                              : ' Add Comments'}
                          </Button>
                        </Col>
                      </Row>
                      <Row className={'details-filter'}>
                        <Col xs={10} className={'pt-2'}>
                          <Row>
                            {isUserPRO ? (
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label">Brand</Form.Label>
                                  <DropdownSelect
                                    className="form-control-one"
                                    name="fromCustomerId"
                                    id="fromCustomerId"
                                    onChange={(e) => handleClientHandler(e)}
                                    disabled={isRejected}
                                    value={workOrderForm?.values?.fromCustomerId}
                                    options={clients}
                                    getOptionLabel={(option) => `${option.name}`}
                                    getOptionValue={(option) => `${option.id}`}
                                  />
                                </Form.Group>
                              </Col>
                            ) : null}
                            <Col xs={3}>
                              <Form.Group
                                className="date-picker-bg-img-icon left-picker "
                                id="startDate">
                                <Form.Label className="label">
                                  Start Date <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <RcylDatePicker
                                  // parentEl="#startDate"
                                  singleDatePicker
                                  disabled={isProgress}
                                  startDate={workOrderForm.values.fromDate}
                                  onApply={({ startDate, endDate }, $event) => {
                                    !deliveryExists &&
                                      workOrderForm.setValues({
                                        ...workOrderForm?.values,
                                        fromDate: startDate,
                                        toDate: null,
                                      });
                                  }}
                                  isInvalid={!!workOrderForm.errors.fromDate}
                                  errorMessage={workOrderForm.errors.fromDate}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group
                                className="date-picker-bg-img-icon left-picker "
                                id="toDate">
                                <Form.Label className="label ">
                                  Estimated delivery date{' '}
                                  <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <RcylDatePicker
                                  minDate={workOrderForm.values.fromDate}
                                  singleDatePicker
                                  disabled={isProgress}
                                  startDate={workOrderForm.values.toDate ?? ''}
                                  onApply={({ startDate, endDate }, $event) => {
                                    !deliveryExists &&
                                      workOrderForm.setFieldValue('toDate', startDate);
                                  }}
                                  isInvalid={!!workOrderForm.errors.toDate}
                                  errorMessage={workOrderForm.errors.toDate}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="label ">
                                  Entity Type <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  className="px-3 form-control-one"
                                  name="entityType"
                                  id="entityType"
                                  onChange={dropDownHandler}
                                  isInvalid={!!workOrderForm.errors.entityType}
                                  value={workOrderForm?.values?.entityType}>
                                  <option key={'ENTITY_TYPE_NONE'} value="">
                                    Select option
                                  </option>
                                  {ENTITY_TYPE_OPTIONS.map((type) => {
                                    return (
                                      <option key={type?.id} value={type?.label}>
                                        {type?.label}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group
                                className="date-picker-bg-img-icon left-picker "
                                id="deliveryDate">
                                <Form.Label className="label ">
                                  PO Date <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <RcylDatePicker
                                  disabled={isProgress}
                                  minDate={poDate}
                                  singleDatePicker
                                  startDate={workOrderForm.values.poDate}
                                  onApply={({ startDate, endDate }, $event) => {
                                    workOrderForm.setFieldValue('poDate', startDate);
                                  }}
                                  showDropdowns={true}
                                  isInvalid={!!workOrderForm.errors.poDate}
                                  errorMessage={workOrderForm.errors.poDate}
                                />
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="label ">
                                  PO Number <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 100, hide: 400 }}
                                  overlay={
                                    <Tooltip id="button-tooltip">
                                      This is external Reference ID
                                    </Tooltip>
                                  }>
                                  <FontAwesomeIcon
                                    className="text-muted ml-1"
                                    icon={faQuestionCircle}
                                  />
                                </OverlayTrigger>
                                <Form.Control
                                  name="poNumber"
                                  id="poNumber"
                                  disabled={isProgress}
                                  isInvalid={!!workOrderForm.errors.poNumber}
                                  {...workOrderForm.getFieldProps('poNumber')}
                                  value={
                                    workOrderForm?.values?.poDetails &&
                                    workOrderForm?.values?.poDetails.length
                                      ? workOrderForm?.values?.poDetails
                                          ?.map((poDetail) => {
                                            return poDetail.purchaseOrderNumber;
                                          })
                                          .join(', ')
                                      : workOrderForm?.values?.poNumber || ''
                                  }></Form.Control>
                              </Form.Group>
                              <p className="error-style">{workOrderForm.errors.poNumber}</p>
                            </Col>
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="label ">
                                  Payment Terms(Days){' '}
                                  <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <Form.Control
                                  name="paymentTerm"
                                  id="paymentTerm"
                                  type="number"
                                  onKeyDown={handleKeyDown}
                                  isInvalid={!!workOrderForm.errors.paymentTerm}
                                  {...workOrderForm.getFieldProps('paymentTerm')}></Form.Control>
                              </Form.Group>
                              {workOrderForm.errors.paymentTerm && (
                                <p className="error-style">Payment Term required.</p>
                              )}
                            </Col>
                            {!isNonPlastic && (
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label ">
                                    Traceability Documents{' '}
                                    <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    as="select"
                                    className="px-3 form-control-one"
                                    name="traceabilityDocuments"
                                    id="traceabilityDocuments"
                                    onChange={dropDownHandler}
                                    isInvalid={!!workOrderForm.errors.traceabilityDocuments}
                                    value={workOrderForm?.values?.traceabilityDocuments}>
                                    <option key={'TRACEABILITY_DOCS_NONE'} value="">
                                      Select option
                                    </option>
                                    {TRACEABILITY_DOCS_OPTIONS.map((type) => {
                                      return (
                                        <option key={type?.id} value={type?.value}>
                                          {type?.label}
                                        </option>
                                      );
                                    })}
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            )}
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="label ">
                                  Fulfillment Year
                                  <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                <Form.Control
                                  as="select"
                                  className="px-3 form-control-one"
                                  name="fulfillmentYear"
                                  id="fulfillmentYear"
                                  // disabled={}
                                  onChange={finYearHandler}
                                  isInvalid={
                                    workOrderForm?.errors?.fulfillmentYearStartDate &&
                                    (!!workOrderForm.errors.fulfillmentYearStartDate ||
                                      !!workOrderForm.errors.fulfillmentYearEndDate ||
                                      !workOrderForm.values.fulfilledYear)
                                  }
                                  value={workOrderForm.values.fulfilledYear}>
                                  <option key={'FIN_YEAR_NONE'}>Select option</option>
                                  {account?.financialYears?.map((ele) => {
                                    return (
                                      <option key={ele?.label} value={ele?.fulfilledYear}>
                                        {ele?.label}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {workOrderForm.errors.fulfillmentYearStartDate}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="label ">Fulfillment Cycle</Form.Label>
                                <Form.Control
                                  as="select"
                                  className="px-3 form-control-one"
                                  name="fulfillmentCycle"
                                  id="fulfillmentCycle"
                                  onChange={(e) => {
                                    workOrderForm.setFieldValue('fulfillmentCycle', e.target.value);
                                  }}
                                  isInvalid={
                                    !!workOrderForm.errors.fulfillmentCycle ||
                                    !!workOrderForm.errors.fulfillmentCycle ||
                                    !!workOrderForm.fulfillmentCycle
                                  }
                                  value={workOrderForm.values.fulfillmentCycle}>
                                  <option key={'FULFILLMENTCYCLE'}>Select option</option>
                                  {FULFILLMENT_CYCLES?.map((ele) => {
                                    return (
                                      <option key={ele?.label} value={ele?.value}>
                                        {ele?.label}
                                      </option>
                                    );
                                  })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {workOrderForm.errors.fulfillmentCycle}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col xs={3}>
                              <Form.Group>
                                <Form.Label className="mb-0" style={{ display: 'block' }}>
                                  Additional Invoicing requirements{' '}
                                  <span className="mandatory-astrik-color">*</span>
                                </Form.Label>
                                {[
                                  { id: '1', label: 'Yes', value: true },
                                  { id: '2', label: 'No', value: false },
                                ].map((item, id) => (
                                  <Form.Check
                                    key={`${id}`}
                                    inline
                                    type="radio"
                                    name={`isInvoicing-${id}`}
                                    id={`inline-radio-${id + 1}`}
                                    label={item?.label}
                                    className="invoicing-radio mt-3"
                                    onChange={() => {
                                      workOrderForm.setValues({
                                        ...workOrderForm?.values,
                                        isInvoicing: item.value,
                                        comments: null,
                                      });
                                      if (item?.value) {
                                        setCommentValidation({
                                          comments: Yup.string('Comments required')
                                            .typeError('Comments are required')
                                            .required('Comments are required'),
                                        });
                                      }
                                      if (!item?.value) {
                                        setCommentValidation({});
                                      }
                                    }}
                                    checked={workOrderForm.values?.isInvoicing === item.value}
                                  />
                                ))}
                                {workOrderForm.errors?.isInvoicing && (
                                  <div className="invalid-feedback-form-check">
                                    {workOrderForm.errors?.isInvoicing}
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            {workOrderForm?.values?.isInvoicing && (
                              <Col xs={3}>
                                <Form.Group>
                                  <Form.Label className="label ">
                                    Comments <span className="mandatory-astrik-color">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    name="comments"
                                    id="comments"
                                    type="comments"
                                    isInvalid={!!workOrderForm.errors.comments}
                                    {...workOrderForm.getFieldProps('comments')}></Form.Control>
                                </Form.Group>
                                {workOrderForm.errors.comments && (
                                  <p className="error-style">Comments Required.</p>
                                )}
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col xs={2} className={'pt-2'}>
                          <Row>
                            <UploadScrollableCard
                              documents={workOrderForm.values?.documents || []}
                              handleDocChange={handleDocumentChange}
                              deletDoc={deletDocument}
                              canUpdate={true}
                            />
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Row className={'padding-2rem details-filter'}>
                      <Col xs={12}>
                        <h6 className="mb-0">Target Allocation</h6>
                      </Col>
                      <Col xs={12} className={'pt-3'}>
                        <BrandWorkOrderItemsEdit
                          isEdit={isEdit}
                          isNotMandatory={workOrderForm?.values?.draft}
                          basicDetails={workOrderForm.values}
                          updateHandler={updateDataToTable}
                          addNewRowHandler={addOrCopyRow}
                          deleteRowHandler={deleteRow}
                          copyRowHandler={addOrCopyRow}
                          deliveryExists={deliveryExists}
                          rowsToHighlight={rowsToHighlight ? rowsToHighlight : []}
                          browseNodes={browseNodes}
                          regions={regions}
                          addRow={true}
                          orderSummary={orderSummary}
                          isHUL={account?.user?.profile?.id === 9053}
                          isUserPRO={isUserPRO}
                          handleKeyDown={handleKeyDown}
                          isBRANDWO={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <AccessDenied />
              )}
            </Card.Body>
          </Form>
        </Card>
      </React.Suspense>
      <CommentsModal
        modalShow={modalShow}
        handleClose={handleClose}
        workOrderForm={workOrderForm}
        mode={workOrderId ? 'edit' : 'add'}
      />
    </div>
  );
};

export default WorkOrderCreateBrandPRO;
